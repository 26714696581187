import { createInjectionState } from '@vueuse/core';

const state = (collection: MaybeRefOrGetter<string | undefined>) => {
  return toValue(collection);
};

const [useProvideCollection, useCollection] = createInjectionState(state);

export { useProvideCollection };
export { useCollection };
